var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-cancel-flight",
      "title": _vm.actionCancelTrip === 'REFUND' && _vm.airlines.some(function (a) {
        return ['VJ', 'VZ'].includes(a);
      }) ? _vm.$t('reservation.refundTicketVJ') : _vm.actionCancelTrip === 'REFUND' ? _vm.$t('reservation.refundTicket') : _vm.$t('reservation.cancelFlight'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-75",
      "footer-class": "px-50 m-0",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.showHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill px-1",
          attrs: {
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "border-0 px-1",
          attrs: {
            "variant": _vm.actionCancelTrip === 'REFUND' ? 'outline-warning' : 'outline-danger',
            "disabled": !_vm.indexSelectedFlightToCancel.length,
            "pill": ""
          },
          on: {
            "click": _vm.submitAndCheckPriceHandle
          }
        }, [_vm.actionCancelTrip === 'REFUND' && _vm.airlines.some(function (a) {
          return ['VJ', 'VZ'].includes(a);
        }) ? _c('span', [_vm._v(" Tính tiền hoàn định danh ")]) : _c('span', [_vm._v(" Tiếp tục ")])])];
      }
    }])
  }, [_c('div', {
    staticClass: "text-heading-5"
  }, [_vm._v(" " + _vm._s(_vm.actionCancelTrip === 'REFUND' ? _vm.$t('reservation.selectItineraryToRefund') : _vm.$t('reservation.selectItineraryToCancel')) + " ")]), _c('b-card', {
    staticClass: "mt-1",
    attrs: {
      "body-class": "p-75"
    }
  }, [_c('b-form-group', {
    attrs: {
      "disabled": ['VN1A', 'VN1A_MT'].includes(_vm.getBookingData.source)
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [!['VJ'].includes(_vm.getBookingData.source) && _vm.getBookingData.itineraries.length > 1 ? _c('b-form-checkbox', {
          staticClass: "custom-checkbox-label mb-1",
          attrs: {
            "disabled": ['VJ', 'VU'].includes(_vm.getBookingData.source) && _vm.actionCancelTrip === 'REFUND' || ['1A', 'VN1A', 'VN1A_MT'].includes(_vm.getBookingData.source) || !['QH'].includes(_vm.getBookingData.source) && !_vm.isEmpty(_vm.segmentIdToDisabled),
            "aria-describedby": "flights",
            "aria-controls": "flights"
          },
          on: {
            "change": _vm.toggleAll
          },
          model: {
            value: _vm.allSelected,
            callback: function callback($$v) {
              _vm.allSelected = $$v;
            },
            expression: "allSelected"
          }
        }, [_c('div', {
          staticClass: "text-body-2"
        }, [_vm._v(" " + _vm._s(_vm.allSelected ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "id": "checkbox-group-cancel-flight",
            "aria-describedby": ariaDescribedby,
            "stacked": "",
            "name": "flights"
          },
          model: {
            value: _vm.indexSelectedFlightToCancel,
            callback: function callback($$v) {
              _vm.indexSelectedFlightToCancel = $$v;
            },
            expression: "indexSelectedFlightToCancel"
          }
        }, _vm._l(['1G'].includes(_vm.getBookingData.source) ? _vm.addArunksToItineraries(_vm.getBookingData, true) : _vm.getBookingData.itineraries, function (itinerary, indexItinerary) {
          return _c('div', {
            key: indexItinerary,
            staticClass: "d-flex"
          }, [_c('b-form-checkbox', {
            staticClass: "custom-checkbox-label mb-50",
            attrs: {
              "value": indexItinerary,
              "disabled": ['VN1A_MT', 'VN1A'].includes(_vm.getBookingData.source) ? false : ['1A'].includes(_vm.getBookingData.source) || _vm.disableSegmentCheckbox(_vm.actionCancelTrip, _vm.segmentIdToDisabled, itinerary) || _vm.disabledByFareRuleRefund(_vm.actionCancelTrip, itinerary)
            }
          }, [_vm.isArray(itinerary) ? _c('div', _vm._l(itinerary, function (segment) {
            return _c('div', {
              key: segment.segmentId,
              staticClass: "d-flex align-items-center"
            }, [_c('span', {
              staticClass: "text-dark font-weight-bolder"
            }, [_vm._v(" " + _vm._s(_vm.convertShortTrip(segment)))])]);
          }), 0) : _c('div', [_c('span', {
            staticClass: "text-dark font-weight-bolder"
          }, [_vm._v(" " + _vm._s(_vm.convertShortTrip(itinerary)))])])])], 1);
        }), 0)];
      }
    }])
  })], 1), ['VJ'].includes(_vm.getBookingData.source) && _vm.actionCancelTrip === 'REFUND' ? _c('b-card', {
    staticClass: "font-weight-bolder mt-1"
  }, [_c('div', [_c('BBadge', {
    staticClass: "font-medium-3 text-danger px-1 py-50 border-danger mb-25",
    attrs: {
      "pill": "",
      "variant": "light"
    }
  }, [_vm._v(" ! Lưu ý quan trọng: ")]), _c('div', [_vm._v(" - Phí treo sẽ tự động cấn trừ vào tiền hoàn bảo lưu. ")]), _c('div', {
    staticClass: "text-danger"
  }, [_vm._v(" - Đại lý vui lòng xử lý phí treo (nếu có) trước khi làm hoàn để tối đa hóa số tiền được bảo lưu định danh. ")]), _c('div', [_vm._v(" - Xóa \"em bé đi kèm\" (nếu cần) trước khi thao tác Hoàn bảo lưu để tối ưu hóa số tiền được hoàn. ")]), _c('div', [_vm._v(" - Danh sách code hoàn bảo lưu ở mục: Công nợ -> Hoàn định danh VJ ")])], 1), _c('div', {
    staticClass: "mt-1"
  }, [_c('div', {
    staticClass: "font-medium-3 text-warning mb-25"
  }, [_vm._v(" Điều kiện hoàn định danh Vietjet Air: ")]), _c('div', [_vm._v(" - Hành trình đã xuất vé ")]), _c('div', [_vm._v(" - Eco, Deluxe: trước thời gian khởi hành 24 tiếng ")]), _c('div', [_vm._v(" - Skyboss, Skyboss Business: trước thời gian khởi hành 3 tiếng ")])])]) : _vm._e(), ['VJ'].includes(_vm.getBookingData.source) && _vm.actionCancelTrip === 'CANCEL' ? _c('div', [_vm.isDisableCancelForF3VJ ? _c('BAlert', {
    staticClass: "px-1 py-50",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Tài khoản đại lý của bạn không có quyền huỷ VJ, vui lòng liên hệ booker. ")]) : _vm._e(), _c('b-card', {
    staticClass: "text-warning font-weight-bolder mt-2"
  }, [_vm._v(" Điều kiện huỷ hành trình Vietjet Air: "), _c('div', [_vm._v(" - Các hành trình chưa xuất vé ")])])], 1) : _vm._e(), _vm.getBookingData.source === 'VU' && _vm.actionCancelTrip === 'REFUND' ? _c('b-card', {
    staticClass: "text-warning font-weight-bolder mt-2"
  }, [_vm._v(" Điều kiện hoàn Vietravel Airlines: "), _c('div', [_vm._v(" - Hành trình đã xuất vé ")]), _c('div', [_vm._v(" - Vé được hoàn: Trước thời gian khởi hành 3 tiếng ")])]) : _vm._e(), _vm.getBookingData.source === 'VU' && _vm.actionCancelTrip === 'CANCEL' ? _c('b-card', {
    staticClass: "text-warning font-weight-bolder mt-2"
  }, [_vm._v(" Điều kiện huỷ hành trình Vietravel Airlines: "), _c('div', [_vm._v(" - Hành trình chưa xuất vé ")])]) : _vm._e(), _vm.actionCancelTrip === 'CANCEL' ? _c('BAlert', {
    staticClass: "px-2 py-1 mt-50",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Hủy chuyến bay sẽ hủy hẹn giờ xuất vé (nếu có) ")]) : _vm._e(), _vm.errorCancel ? _c('div', [_c('div', {
    staticClass: "text-danger text-heading-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.error')) + " ")]), _c('div', [_vm._v(_vm._s(_vm.errorCancel))])]) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "modal-cancel-flight-price",
      "title": _vm.$t('reservation.confirm'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "body-class": "px-75",
      "no-close-on-backdrop": true
    },
    on: {
      "hide": _vm.hidePriceModalHandle,
      "show": _vm.showPriceHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref3) {
        var close = _ref3.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": _vm.actionCancelTrip === 'REFUND' ? 'warning' : 'danger',
            "disabled": _vm.disabledSummitModalCancelFlightPrice(),
            "pill": ""
          },
          on: {
            "click": _vm.submitCancelFlightHandle
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('reservation.confirm')))])])];
      }
    }])
  }, [_c('div', {
    staticClass: "text-heading-5 mb-1"
  }, [_vm._v(" " + _vm._s(_vm.actionCancelTrip === 'REFUND' ? _vm.$t('reservation.confirmRefundFlight') : _vm.$t('reservation.confirmCancelFlight')) + " ")]), _vm._l(_vm.indexSelectedFlightToCancelComputed, function (itineraryIndex, index) {
    return _c('div', {
      key: index,
      staticClass: "border rounded p-75 mb-1"
    }, [_vm.getBookingData.source.includes('1S') ? _c('div', [_c('span', {
      staticClass: "text-dark font-weight-bolder pl-md-1"
    }, [_vm._v(_vm._s(_vm.convertShortTrip(_vm.addArunksToItineraries(_vm.getBookingData, true)[itineraryIndex])))])]) : _c('div', _vm._l(_vm.getBookingData.itineraries[itineraryIndex], function (segment, indexSegment) {
      return _c('div', {
        key: indexSegment
      }, [_c('span', {
        staticClass: "text-dark font-weight-bolder pl-md-1"
      }, [_vm._v(_vm._s(_vm.convertShortTrip(segment)))])]);
    }), 0)]);
  }), _c('div', {
    staticClass: "my-2"
  }, [_vm.priceCancel && _vm.getBookingData.source !== 'VU' ? _c('div', [_vm.getBookingData.source === 'QH' && _vm.priceCancel.cancelFee ? _c('b-row', {
    staticClass: "font-weight-bolder"
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_vm._v(" Phí hoàn vé: ")]), _c('b-col', {
    staticClass: "text-right text-warning",
    attrs: {
      "md": "4",
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceCancel.cancelFee)) + " " + _vm._s(_vm.priceCancel.currency) + " ")])], 1) : _vm._e(), _c('b-row', {
    staticClass: "font-weight-bolder"
  }, [!['REFUND'].includes(_vm.actionCancelTrip) && ['VJ'].includes(_vm.getBookingData.source) ? [_c('b-col', {
    staticClass: "text-danger fw-700",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "mb-50"
  }, [_vm._v(" Lưu ý: ")]), _c('p', [_vm._v("Hủy chuyến bay sẽ có phí treo, AG lưu ý không sử dụng lại code đã hủy. Hoặc gọi tổng đài để gỡ phí treo trước khi thao tác lại trên code.")])])] : [_c('b-col', {
    staticClass: "text-right"
  }, [_c('span', {
    staticClass: "font-medium-1"
  }, [_vm._v(_vm._s(['REFUND'].includes(_vm.actionCancelTrip) ? _vm.$t('reservation.refundFlightAmount') : _vm.$t('reservation.chargeAmount')) + ": ")])]), _c('b-col', {
    staticClass: "text-right text-warning font-medium-2",
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceCancel.total)) + " " + _vm._s(_vm.priceCancel.currency) + " ")])]], 2)], 1) : _vm.priceCancel && _vm.getBookingData.source === 'VU' ? _c('div', [_c('p', {
    staticClass: "d-flex justify-content-between"
  }, [_c('span', {
    staticClass: "text-dark"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.refundFee')) + ": ")]), _c('span', {
    staticClass: "font-medium-3 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceCancel.chargeAmount.total)) + " ")])]), _c('p', {
    staticClass: "d-flex justify-content-between"
  }, [_c('span', {
    staticClass: "text-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.refundFlightAmount')) + ": ")]), _c('span', {
    staticClass: "font-medium-3 text-warning font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceCancel.refundAmount.total)) + " ")])])]) : _vm.errorCancel ? _c('div', [_c('div', {
    staticClass: "text-danger text-heading-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.error')) + " ")]), _c('div', [_vm._v(_vm._s(_vm.errorCancel))])]) : _vm.priceCancel === false ? _c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "mb-50 text-info font-italic"
  }, [_vm._v(" Đang tính giá, vui lòng đợi... ")]), _c('b-spinner', {
    attrs: {
      "variant": "info"
    }
  })], 1) : _vm._e(), ['VJ'].includes(_vm.getBookingData.source) && ['REFUND'].includes(_vm.actionCancelTrip) ? _c('b-row', {
    staticClass: "text-warning font-weight-bold mx-0 mt-2 flex-column",
    class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-3'
  }, [_c('p', {
    staticClass: "mb-50"
  }, [_vm._v(" Lưu ý: ")]), _c('p', [_vm._v("Vui lòng kiểm tra lại hành lý ký gửi sau khi đổi.")])]) : _vm._e()], 1), ['VJ'].includes(_vm.getBookingData.source) && _vm.getBookingData.status === 'HOLD' && _vm.priceCancel && _vm.priceCancel.total ? _c('div', [_c('BAlert', {
    staticClass: "mt-1 p-1",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.removeCancelFee')) + " ")])], 1) : _vm._e(), _vm.actionCancelTrip === 'CANCEL' ? _c('BAlert', {
    staticClass: "px-2 py-1 mt-50",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Hủy chuyến bay sẽ hủy hẹn giờ xuất vé (nếu có) ")]) : _vm._e(), _c('IAmConfirm', {
    staticClass: "mt-1",
    attrs: {
      "is-confirm": _vm.isConfirm
    },
    on: {
      "update:isConfirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      },
      "update:is-confirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      }
    }
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }